<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>🎉반가워요 회원님🎉</h3>
            <p class="single-file-content">
                쿠폰함에 1개월 무료 쿠폰을 넣어드렸어요.<br/>
                지금 쿠폰을 이용해서 스페이스를 등록해보세요!
            </p>
            <div class="single-file-check">
                <p style="margin-bottom: 0px;" @click="linkMySpace()">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "@/service/axios";
import VueCookies from "vue-cookies";

export default {
  name: 'GreetModal',
  methods: {
    linkMySpace () {
      var userId = VueCookies.get("userId");
      var signupCouponAt = 'N';
      var aToken = VueCookies.get("aToken");
      var headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/user/checkSignupCouponAt", { userId, signupCouponAt }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$store.commit("setSignupCouponAt", signupCouponAt);
            this.$emit('closeModal');
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
  @media all and (max-width: 500px) {
    .single-file-check p {
      font-size: 15px !important;
      color: #FF2D55;
      font-weight: 400;
    }
    .single-file-content {
      padding-top: 0px;
    }
  }
</style>
